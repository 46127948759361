<template>
    <!--目标详情-->
    <div>
        <!--添加目标开始-->
        <div class="target-now">
            <div class="target-name">
                当前目标:<span class="target-text">{{  bigTarget.tag ? bigTarget.tag.name : "请选择目标" }}</span>
            </div>
            <div class="add-target">
                <van-field
                    v-model="addTarget"
                    rows="1"
                    autosize
                    :autofocus="isFocus"
                    type="textarea"
                    class="add-target-input" maxlength='200' @blur="updateTargets(userTags.tag_id)"
                />
                <img src="~assets/images/user/del.png"  alt="" @click="delTargte">
            </div>
            <div class="target-time">
                <div class="target-time-img">
                    <div class="target-time-bg"></div>
                    <img class="target-time-icon" src="~assets/images/user/alarm-clock.png"/>
                </div>
                <div class="target-time-item align-center" @click="clickDateEndChange">
                    <input class="target-time-end" v-model="end_at" ref="targetTime" placeholder="请选择目标时间">
                </div>
                <img class="calendar-icon"  @click.stop="clickDateEndChange" src="~assets/images/user/calendar.png"/>
            </div>
        </div>
        <!--添加目标结束-->
        <!--选择时间-->
        <van-popup v-model:show="showPopup" position="bottom" :style="{ height: '50%' }" >
            <van-datetime-picker
                v-if="showDatetimeEnd"
                 v-model="currentDateEnd"
                 type="date"
                 title="选择年月日"
                 :min-date="minDate"
                 :max-date="maxDate"
                 @confirm="getEndTime"
                @cancel="cancelTime"
            ></van-datetime-picker>
        </van-popup>
        <!--选择时间-->
        <van-toast id="van-toast" />
    </div>
</template>

<script>
export default {
    name: "show",
    data(){
        return {
            addTarget: '',
            end_at:'',
            userTags: {},
            isFocus:false,
            smallTarget: {},
            showDatetimeEnd: false,
            currentDateEnd: new Date(2020, 0, 1),
            minDate: new Date(new Date().setDate(new Date().getDate()+1)),
            showPopup: false,
            bigTarget: {},
            maxDate: new Date()
        }
    },
    created(){
        this.getUserTags()
        this.getSmallTarget()
        this.getBigTag()
    },
    methods:{
        //获取用户目标
        getUserTags(){
            let _this = this
            let url = _this.$api.UserTags
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    _this.userTags = res.data.data
                    // reactive(_this.userTags)
                }
            } )
        },
        //获取小目标详情
        getSmallTarget(){
            let _this = this
            let url = _this.$api.SubTarget + '/' + _this.$route.params.id
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    if (res.data.data.end_at){
                        res.data.data.end_at = res.data.data.end_at.split(' ')[0]
                    }
                    _this.addTarget = res.data.data.content
                    _this.end_at =  res.data.data.end_at
                }
            } )
        },
        //修改目标
        updateTargets(){
            let _this = this
            if (_this.addTarget.trim().length == 0){
              return
            }
            if ((0 < _this.addTarget.trim().length && _this.addTarget.trim().length < 2) || _this.addTarget.trim().length > 200){
                _this.$toast('请输入2-50字');
                return
            }
            let url = _this.$api.SubTarget
/*            let dataTime = new Date(new Date(new Date(_this.end_at).toLocaleDateString()).getTime()+24*60*60*1000-1)
            this.end_at = dataTime.getFullYear() + '-' +(dataTime.getMonth()+1) + '-' + dataTime.getDate() + " " + dataTime.getHours() + ":" + dataTime.getMinutes() + ':' + dataTime.getSeconds()*/
            let data = {
                id: _this.$route.params.id,
                end_at: _this.filterTime(_this.end_at),
                content: _this.addTarget,
            }
            _this.$http.post(url,data,true).then( res => {
                if(res.data.success){

                }
            } )
        },
        //获取用户大目标
        getBigTag(){
            let _this = this
            let url = _this.$api.UserTags + '/big'
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    if (res.data.data.end_at){
                        res.data.data.end_at = res.data.data.end_at.split(' ')[0]
                        let DATA = res.data.data.end_at.split('-')
                        _this.maxDate =  new Date(DATA[0], DATA[1]-1, DATA[2])
                    }else {
                        _this.maxDate = new Date(2025, 12, 1)
                    }
                    _this.bigTarget = res.data.data
                }
            } )
        },
        clickDateEndChange(){
            this.$refs.targetTime.blur()
            this.showPopup = true
            this.showDatetimeEnd = true
        },
        // 选择时间
        getEndTime(val){
            this.showPopup = false
/*            let data = new Date(new Date(new Date(val).toLocaleDateString()).getTime()+24*60*60*1000-1)
            this.end_at = data.getFullYear() + '-' +(data.getMonth()+1) + '-' + data.getDate() + " " + data.getHours() + ":" + data.getMinutes() + ':' + data.getSeconds()*/
            this.end_at = this.filterTime(val).split(' ')[0]
            this.currentDateEnd = new Date()
            if (this.bigTarget.end_at === null){
                this.updateTargets()
            }
            else {
                if (new Date(this.bigTarget.end_at) <  new Date(this.end_at)){
                    this.end_at = this.bigTarget.end_at
                    this.updateTargets()
                }else {
                    this.updateTargets()
                }
            }
        },
        // 取消时间
        cancelTime(){
            this.showPopup = false
            this.currentDateEnd = new Date()
        },
        //删除小目标
        delTargte(){
            let _this = this
            let url = _this.$api.Targets + "/" + _this.$route.params.id
            _this.$http.del(url,true).then( res => {
                if(res.data.success){
                    _this.$toast('删除成功');
                    _this.$router.go(-1)
                }
            } )
            _this.getUserTags(this.searchTarget,this.status)
        },
        filterTime(date){
            let dataTime = new Date(new Date(new Date(date).toLocaleDateString()).getTime()+24*60*60*1000-1)

            let month = 0
            let hours = 0
            let day = 0
            let minutes = 0
            let seconds = 0
            if ((dataTime.getMonth()+1) < 10){
                month = '0'+(dataTime.getMonth()+1)
            }else {
                month = dataTime.getMonth()+1
            }


            if ((dataTime.getHours()) < 10){
                hours = '0'+(dataTime.getHours())
            }else {
                hours = dataTime.getHours()
            }

            if ((dataTime.getDate()) < 10){
                day = '0'+(dataTime.getDate())
            }else {
                day = dataTime.getDate()
            }


            if ((dataTime.getMinutes()) < 10){
                minutes = '0'+(dataTime.getMinutes())
            }else {
                minutes = dataTime.getMinutes()
            }


            if ((dataTime.getSeconds()) < 10){
                seconds = '0'+(dataTime.getSeconds())
            }else {
                seconds = dataTime.getSeconds()
            }
            // return date.getFullYear() +'-'+ month +'-'+ day + " " + hours + ":" + minutes + ':' + seconds

            let time = dataTime.getFullYear() +'-'+ month +'-'+ day + " " + hours + ":" + minutes + ':' + seconds
            return time
        }
    }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
//添加目标开始
.target-now{
    margin: 40/$r 34/$r 30/$r;
    .target-name{
        font-size: 28/$r;
        font-weight: 400;
        color: #666666;
        .target-text{
            margin-left: 6/$r;
            padding: 0 10/$r 13/$r 10/$r;
            color: #FF6B29;
            border-bottom: 1px solid #BFBFBF;;
        }
    }
    .target-time{
        display: flex;
        margin: 27/$r 0 20/$r 0;
        position: relative;
        .target-time-item{
            width: 100%;
            margin-left: 20/$r;
            justify-content: space-between;
        }
        .target-time-img{
            position: relative;
            width: 51/$r;
            height: 51/$r;
            .target-time-bg{
                width: 51/$r;
                height: 51/$r;
                background: #F3CF9E;
                border-radius: 50%;
            }
            .target-time-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -13/$r;
                margin-left: -14/$r;
                width: 26/$r;
                height: 26/$r;
            }
        }
        .target-time-end{
            flex: 1;
            height: 65/$r;
            background: #F9F9F9;
            padding-left: 26/$r;
            box-sizing: border-box;
            border-radius: 8/$r;
            font-size: 28/$r;
            font-weight: 400;
            color: #666666;
            //text-align: center;
            border: none;
            line-height: 65/$r;
        }
        .target-time-start{
            margin-left: 20/$r;
            margin-right: 10/$r;
        }
        .calendar-icon{
            position: absolute;
            right: 18/$r;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 36/$r;
            height: 36/$r;
        }
    }
    .add-target{
        position: relative;
        margin-top: 80/$r;
        background: #F9F9F9;
        border-radius: 8/$r;
      :deep(.van-cell){
            margin-right: 0;
            .van-field__control{
                font-size: 24/$r;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 36/$r;
            }
            &:after{
                border-bottom: none;
            }
        }
        img{
            position: absolute;
            bottom: 14/$r;
            right: 16/$r;
            width: 36/$r;
            height: 36/$r;
        }
        .add-target-input{
            background: #F9F9F9;
            //border-radius: 4/$r;
            padding: 28/$r 20/$r 38/$r 18/$r;
            border: none;
            line-height: 30/$r;
        }
        .placeholder-class{
            font-size: 24/$r;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #A6A6A6;
            line-height: 24/$r;
        }
    }

}
//时间选择样式
:deep(.van-popup){
    .van-picker__toolbar{
        .van-picker__confirm{
            color: #FF6B29;
        }
    }

}
//添加目标结束
</style>
